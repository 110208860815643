import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SubCategoriasService  {

  constructor (private _http: HttpClient, private appS: AppService) {
   
  }

  getSubCategoryById(id) {
    return this._http.get(this.appS.url + '/admin/data_entry/sub_category/by_id?id=' + id, { headers: this.appS.getheaderWithBearer() });
  }
  getSubCategoriasByTextNew(text) {
    return this._http.get(this.appS.url + '/admin/data_entry/sub_category/new_get_text?page=1&amount=20&text=' + text, { headers: this.appS.getheaderWithBearer() });
  }
  getSubCategoriasNew(page, enabled) {
    return this._http.get(this.appS.url + '/admin/data_entry/sub_category/new_get?page=' + page + '&amount=20&enabled=' + enabled, { headers: this.appS.getheaderWithBearer() });
  }

  getAseguradoras() {
    return this._http.get(this.appS.url + '/admin/data_entry/sub_category/insurers?size=2000', { headers: this.appS.getheaderWithBearer() });
  }

  getSubCategorias() {
    return this._http.get(this.appS.url + '/admin/data_entry/sub_category?size=2000', { headers: this.appS.getheaderWithBearer() });
  }

  searchSubCategorias(text) {
    return this._http.get(this.appS.url + '/admin/data_entry/sub_category/search?text=' + text +'&size=2000', { headers: this.appS.getheaderWithBearer() });

  }

  getDisabledSubCategorias() {
    return this._http.get(this.appS.url + '/admin/data_entry/sub_category/disabled?size=2000', { headers: this.appS.getheaderWithBearer() });
  }

  createSubCategory(name: string, categoryId: number, aseguradorasSelected) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('categoryId', categoryId.toString());
    formData.append('insurersId', aseguradorasSelected.join(';'));
    return this._http.post(this.appS.url + '/admin/data_entry/sub_category', formData, {headers: this.appS.getheaderWithBearer()});
  }

  editSubCategory(subCategoryId, name, categoryId, aseguradorasSelected){
    const formData = new FormData();
    formData.append('subCategoryId', subCategoryId);
    formData.append('name', name);
    formData.append('categoryId', categoryId);
    formData.append('insurersId', aseguradorasSelected.join(';'));
    debugger
    return this._http.post(this.appS.url + '/admin/data_entry/sub_category/edit', formData, {headers: this.appS.getheaderWithBearer()});
  }

  getCategories() {
    return this._http.get(this.appS.url + '/public/product_category/all', { headers: this.appS.getheaderWithBearer() });
  }

  getInsurers() {
    return this._http.get(this.appS.url + '/public/product_category/all', { headers: this.appS.getheaderWithBearer() });
  }

  deleteSubCategory(subCategoryId) {
    return this._http.delete(this.appS.url + '/admin/data_entry/sub_category?subCategoryId=' + subCategoryId, { headers: this.appS.getheaderWithBearer() });
  }

  renableSubCategory(subCategoryId) {
    const formData = new FormData();
    formData.append('subCategoryId', subCategoryId);
    return this._http.post(this.appS.url + '/admin/data_entry/sub_category/rehabilitar', formData, { headers: this.appS.getheaderWithBearer() });
  }

}
