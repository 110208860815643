import { Injectable } from '@angular/core';
import { Http} from '@angular/http';
import { HttpClient , HttpHeaders, HttpParams } from '@angular/common/http';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import { AppService } from '../../../services/app.service';



@Injectable()
export class LoginService {

    constructor(private _http: HttpClient, private appS: AppService) {
        
    }


    loginPost(email: string, password: string) {
        const formData = new FormData();
        formData.append('username', email);
        formData.append('password', password);
        formData.append('grant_type', 'password');
        
        return this._http.post(this.appS.url + '/oauth/token', formData, {headers: this.appS.headerWithBasic});
    }
    authoritiesGet() {
        return this._http.get(this.appS.url + '/oauth/check_token?token=' + localStorage.getItem('wecover_back_access_token'), {headers: this.appS.headerWithBasic});
    }

    userProfileGet() {
        return this._http.get(this.appS.url + '/public/user/mine', {headers: this.appS.getheaderWithBearer()});
    }
}
