import { Injectable } from '@angular/core';
import {Http, RequestOptionsArgs} from '@angular/http';
import { AppService } from './app.service';
import { HttpClient , HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { JsonPipe } from '@angular/common';

@Injectable()
export class CategoriasService  {
  
  constructor (private _http: HttpClient, private appS: AppService) {
   
  }

  getCategorias() {
    return this._http.get(this.appS.url + '/admin/data_entry/category', { headers: this.appS.getheaderWithBearer() });
  }

  rehabilitarCategoria(categoryId) {
    const formData = new FormData();
    formData.append('categoryId', categoryId);
    return this._http.post(this.appS.url + '/admin/data_entry/category/rehabilitar', formData, { headers: this.appS.getheaderWithBearer() });
  }

  getDisabledCategorias() {
    return this._http.get(this.appS.url + '/admin/data_entry/category/deshabilitados', { headers: this.appS.getheaderWithBearer() });

  }

  crearCategoria(categoryName, menu, priceMin, priceMax, reclamosSelected, imageFile) {
    const formData = new FormData();
    formData.append('name', categoryName);
    formData.append('menu', menu);
    formData.append('priceMin', priceMin.toString());
    formData.append('priceMax', priceMax.toString());
    formData.append('reclamosId', reclamosSelected.join(';'));
    formData.append('imageIcon', imageFile, imageFile.name);
    return this._http.post(this.appS.url + '/admin/data_entry/category', formData, { headers: this.appS.getheaderWithBearer(), responseType: 'text' });
  }

  editarCategoria(categoryId, categoryName, menu, priceMin, priceMax, reclamosSelected, imageFile) {
    debugger
    const formData = new FormData();
    formData.append('id', categoryId.toString());
    formData.append('name', categoryName);
    formData.append('menu', menu);
    formData.append('priceMin', priceMin.toString());
    formData.append('priceMax', priceMax.toString());
    formData.append('reclamosId', reclamosSelected.join(';'));
    imageFile !== undefined ? formData.append('imageIcon', imageFile, imageFile.name ) : imageFile = null;
    return this._http.post(this.appS.url + '/admin/data_entry/category/edit', formData, { headers: this.appS.getheaderWithBearer(), responseType: 'text' });
  }

  searchCategorias(text) {
    return this._http.get(this.appS.url + '/admin/data_entry/category/search?text=' + text +'&size=2000', { headers: this.appS.getheaderWithBearer() });
  }

  getTiposReclamos() {
    return this._http.get(this.appS.url + '/admin/data_entry/category/tipos_reclamos', { headers: this.appS.getheaderWithBearer() });
  }

  deleteSubCategory(categoryId) {
    return this._http.delete(this.appS.url + '/admin/data_entry/category?categoryId=' + categoryId, { headers: this.appS.getheaderWithBearer() });
  }

}
