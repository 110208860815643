import { Injectable } from '@angular/core';
import {AppService} from './app.service';
import {Http} from '@angular/http';
import { HttpClient , HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class ReclamosService  {

  constructor(private _http: HttpClient,private appS: AppService) {
    
  }


  getReclamosByTextNew(text){
    return this._http.get(this.appS.url + '/admin/answer/report/new_get_text?page=1&amount=20&text=' + text, {headers: this.appS.getheaderWithBearer()});
  }
  getReclamosAll() {
    return this._http.get(this.appS.url + '/admin/answer/report?sort=id,DESC&size=2000', {headers: this.appS.getheaderWithBearer()});
  }
  getReclamos() {
    return this._http.get(this.appS.url + '/admin/answer/report?sort=id,DESC&size=10', {headers: this.appS.getheaderWithBearer()});
  }
  getReclamosNew(page) {
    return this._http.get(this.appS.url + '/admin/answer/report/new_get?page=' + page + '&amount=20', {headers: this.appS.getheaderWithBearer()});
  }
  downloadCSVFile() {
    console.log(this.appS.url + '/admin/answer/report/csv'+this.appS.getheaderWithBearerCsv())
    //return this._http.get(this.appS.url + '/admin/answer/report/csv', {headers: this.appS.getheaderWithBearerCsv(), responseType: 'text'});
    return this._http.get(this.appS.url + '/admin/excel/report/csv', {headers: this.appS.getheaderWithBearer(), responseType: 'blob'});
  }
  getReclamoById(id) {
    return this._http.get(this.appS.url + '/admin/answer/report/by_id?id=' + id, {headers: this.appS.getheaderWithBearer()});
  }
  getPropuestaByReportId(id) {
    return this._http.get(this.appS.url + '/admin/answer/report/proposal?reportId=' + id, {headers: this.appS.getheaderWithBearer()});
  }

  aceptarRechazarPedido(reportId: string, status: string) {
    const formData = new FormData();
    formData.append('reportId', reportId);
    formData.append('status', status);

    return this._http.post(this.appS.url + '/admin/answer/report/status', formData, {headers: this.appS.getheaderWithBearer()});
  }
  setReclamosCommentAndPicture(image, message, reclamoId) {
    const formData = new FormData();
    if (image!=null)formData.append('image', image);
    formData.append('message', message);
    formData.append('reclamoId', reclamoId);
    return this._http.post(this.appS.url + '/public/comment', formData, {headers: this.appS.getheaderWithBearer()});
  }

  sendNewOffer(description, amount, reclamoId) {
    const formData = new FormData();
    formData.append('description', description);
    formData.append('amount', amount);
    formData.append('reportId', reclamoId);
    return this._http.post(this.appS.url + '/admin/answer/report/offer', formData, {headers: this.appS.getheaderWithBearer()});
  }

}
