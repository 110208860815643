import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { Http } from '@angular/http';
import { HttpClient , HttpHeaders, HttpParams } from '@angular/common/http';


@Injectable()
export class TipoDeReclamosService  {

  constructor(private _http: HttpClient, private appS: AppService) {
    
  }

  getTypesReclamos() {
    return this._http.get(this.appS.url + '/admin/data_entry/tipos_de_reclamos', {headers: this.appS.getheaderWithBearer()});
  }

  getTypesReclamosDes() {
    return this._http.get(this.appS.url + '/admin/data_entry/tipos_de_reclamos/desabled', {headers: this.appS.getheaderWithBearer()});
  }

  deshabilitarTypesReclamos(i) {
    return this._http.delete(this.appS.url + '/admin/data_entry/tipos_de_reclamos?id=' + i, { headers: this.appS.getheaderWithBearer() });
  }

  rehabilitarTypesReclamos(i) {
    const formData = new FormData();
    formData.append('id', i);
    return this._http.post(this.appS.url + '/admin/data_entry/tipos_de_reclamos/renable', formData, { headers: this.appS.getheaderWithBearer() });

  }

  editarTipoDeReclamo(id, name, imageFile) {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('name', name);
    formData.append('image', imageFile, imageFile.name);
    return this._http.post(this.appS.url + '/admin/data_entry/tipos_de_reclamos/edit', formData, { headers: this.appS.getheaderWithBearer() });
  }

  crearTipoDeReclamos(name, imageFile) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('image', imageFile, imageFile.name);
    return this._http.post(this.appS.url + '/admin/data_entry/tipos_de_reclamos', formData, { headers: this.appS.getheaderWithBearer() });
  }

}
