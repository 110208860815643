import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProductService  {

  constructor (private _http: HttpClient, private appS: AppService) {
   
  }

  getProductsByTextNew(text){
    return this._http.get(this.appS.url + '/admin/data_entry/product_insured/new_get_text?page=1&amount=20&text=' + text, { headers: this.appS.getheaderWithBearer() });
  }

  getProductsNew(page){
    return this._http.get(this.appS.url + '/admin/data_entry/product_insured/new_get?page=' + page + '&amount=20', { headers: this.appS.getheaderWithBearer() });
  }

  getProductById(id) {
    return this._http.get(this.appS.url + '/admin/data_entry/product_insured/by_id?id=' + id, { headers: this.appS.getheaderWithBearer() });
  }

  getProductsByText(text) {
    return this._http.get(this.appS.url + '/admin/data_entry/product_insured/search?text=' + text, { headers: this.appS.getheaderWithBearer() });
  }

  createProduct(name: string, model: string, price: number, description: string, categoryId: string, subCategoryId: string, imageFile: File) {
    
    const formData = new FormData();
    formData.append('name', name);
    formData.append('model', model);
    formData.append('price', price.toString());
    formData.append('description', description);
    formData.append('categoryId', categoryId);
    if (subCategoryId !== null && subCategoryId != '-1') {
      formData.append('subCategoryId', subCategoryId);
    }
    imageFile !== undefined ? formData.append('image', imageFile, imageFile.name ) : imageFile = null;
    debugger
    return this._http.post(this.appS.url + '/admin/data_entry/product_insured', formData, {headers: this.appS.getheaderWithBearer()});
  }

  editProduct(id: string, name: string, model: string, value: string, description: string, categoryId: string, subCategoryId: string, imageFile: File) {

    
    const formData = new FormData();
    formData.append('productId', id);
    formData.append('name', name);
    formData.append('model', model);
    formData.append('value', value);
    formData.append('description', description);
    formData.append('categoryId', categoryId);
    if (subCategoryId !== null && subCategoryId != '-1') {
      formData.append('subCategoryId', subCategoryId);
    }
    imageFile !== undefined ? formData.append('image', imageFile, imageFile.name ) : imageFile = null;

    return this._http.post(this.appS.url + '/admin/data_entry/product_insured/edit' ,formData, {headers: this.appS.getheaderWithBearer()});
  }


  deleteProduct(id: string) {

    return this._http.delete(this.appS.url + '/admin/data_entry/product_insured?productId=' + id, { headers: this.appS.getheaderWithBearer() });
  }

  getCategories() {
    return this._http.get(this.appS.url + '/public/product_category/all', { headers: this.appS.getheaderWithBearer() });
  }
  getSubCategories(categoryId) {
    return this._http.get(this.appS.url + '/public/product_category/all_sub_categories?categoryId=' + categoryId + "&page=1&amount=1000", { headers: this.appS.getheaderWithBearer() });
  }

  getProductsAll() {
    return this._http.get(this.appS.url + '/admin/data_entry/product_insured?size=2000', { headers: this.appS.getheaderWithBearer() });
  }

  getProducts() {
    return this._http.get(this.appS.url + '/admin/data_entry/product_insured?size=20', { headers: this.appS.getheaderWithBearer() });
  }

  getInsurers(){
    return this._http.get(this.appS.url + '/public/product_category/all', { headers: this.appS.getheaderWithBearer() });
  }

  createSubCategory(name, categoryId, insurerId){
    
    const formData = new FormData();
    formData.append('name', name);
    formData.append('categoryId', categoryId.toString());
    formData.append('insurerId', insurerId.toString());
    return this._http.post(this.appS.url + '/admin/data_entry/product_insured', formData, {headers: this.appS.getheaderWithBearer()});
  }


}
