import { Injectable } from '@angular/core';
import {Http, RequestOptionsArgs} from '@angular/http';
import { AppService } from './app.service';
import { HttpClient , HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { JsonPipe } from '@angular/common';

@Injectable()
export class AseguradorasService  {
  
  constructor (private _http: HttpClient, private appS: AppService) {
   
  }

  getInsurers() {
    return this._http.get(this.appS.url + '/admin/data_entry/insurer', { headers: this.appS.getheaderWithBearer() });
  }

  getInsurersDes() {
    return this._http.get(this.appS.url + '/admin/data_entry/insurer/desabled', { headers: this.appS.getheaderWithBearer() });
  }

  desableInsurer(i) {
    return this._http.delete(this.appS.url + '/admin/data_entry/insurer?id=' + i, { headers: this.appS.getheaderWithBearer() });
  }

  renableInsurer(i) {
    const formData = new FormData();
    formData.append('id', i);
    return this._http.post(this.appS.url + '/admin/data_entry/insurer/renable', formData, { headers: this.appS.getheaderWithBearer() });
  }

  crearAseguradora(name, imageFile, por) {
    const formData = new FormData();
    formData.append('name', name);
    por.map((p)=> {
      formData.append('percentages', p.porcentage);
    })
    imageFile !== undefined ? formData.append('image', imageFile, imageFile.name ) : imageFile = null;
    return this._http.post(this.appS.url + '/admin/data_entry/insurer', formData, { headers: this.appS.getheaderWithBearer() });
  }

  editarTipoDeReclamo(id, name, imageFile, por) {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('name', name);
    por.map((p)=> {
      formData.append('percentages', p.percentage);
    })
    imageFile !== undefined ? formData.append('image', imageFile, imageFile.name ) : imageFile = null;
    return this._http.post(this.appS.url + '/admin/data_entry/insurer/edit', formData, { headers: this.appS.getheaderWithBearer() });
  }

}
