import { Injectable } from '@angular/core';
import {AppService} from './app.service';
import {Http} from '@angular/http';
import { HttpClient , HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class ProductosAseguradosService  {

  constructor(private _http: HttpClient,private appS: AppService) {
    
  }

  getProductsInsuredByTextNew(text, dates) {
    return this._http.get(this.appS.url + '/admin/product_insured/new_get_text?page=1&amount=20&text=' + text + dates, {headers: this.appS.getheaderWithBearer()});
  }
  getProductsInsuredByDates(dates) {
    return this._http.get(this.appS.url + '/admin/product_insured/products_dates?page=1&amount=20' + dates, {headers: this.appS.getheaderWithBearer()});
  }
  getProductsNew(page) {
    return this._http.get(this.appS.url + '/admin/product_insured/new_get?page=' + page + '&amount=20', {headers: this.appS.getheaderWithBearer()});
  }
  getProductosAseguradosFifty() {
    return this._http.get(this.appS.url + '/admin/product_insured/all_fifty', {headers: this.appS.getheaderWithBearer()});
  }

  getAllProductosAsegurados() {
    return this._http.get(this.appS.url + '/admin/product_insured/all', {headers: this.appS.getheaderWithBearer()});
  }

  getProductsBySearchRequest(searchParam: string) {
    return this._http.get(this.appS.url + '/admin/product_insured/search?text=' + searchParam, {headers: this.appS.getheaderWithBearer()});
  }

  getProductosAseguradosDetails(id) {
    return this._http.get(this.appS.url + '/admin/product_insured/details?id=' + id, {headers: this.appS.getheaderWithBearer()});
  }

  getProductosAseguradosTotal() {
    return this._http.get(this.appS.url + '/admin/product_insured/total', {headers: this.appS.getheaderWithBearer()});
  }

}
